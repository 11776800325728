<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';
import { required } from 'vuelidate/lib/validators';
import { VclList } from 'vue-content-loading';

export default {
  locales: {
    pt: {
      'Next Rounds': 'Próximas Rodadas',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Next Rounds': 'Rondas Próximos',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      table: {
        heade: [
          '#', 'Sorteio nº', 'Data', 'Doação', 'Prêmios', ''
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      buy: {
        modal: false,
        loading: false,
        alert: {
          type: null,
          message: null
        },
        qty: 1,
        id: null,
        number: null,
        date: null,
        value: 0,
        total: 0
      }
    };
  },
  validations: {
    buy: {
      qty: { required }
    },
  },
  methods: {
    getRounds() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('bingo/rounds')
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
          } else if (response.data.status=='empty') {
            this.statData = []
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    },
    getCard: function (id) {
      api
        .get('bingo/round/' + id)
        .then(response => {
          if (response.data.status=='success') {
            this.buy.id = id
            this.buy.number = response.data.number
            this.buy.date = response.data.date
            this.buy.value = response.data.value
            this.buy.qty = 1
            this.buy.modal = true
          }
        })
    },
    closeCard() {
      this.buy.id = null
      this.buy.number = null
      this.buy.date = null
      this.buy.value = 0
      this.buy.qty = 1
    },
    buyCard() {
      this.$v.buy.$touch();

      if (this.buy.id && this.buy.qty) {
        this.buy.loading = true

        api
          .post('bingo/cards', {
              id: this.buy.id,
              qty: this.buy.qty,
            })
          .then(response => {
            if (response.data.status=='success') {
              this.$v.$reset()

              this.$router.push('/wallet/order/' + response.data.order.id);
            } else {
              this.buy.alert.type = 'alert-danger'
              this.buy.alert.message = response.data.message
            }
            this.buy.loading = false
          })
      }
    }
  },
  mounted() {
    this.getRounds()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Next Rounds') }}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
        <div v-else-if="table.empty">{{ t('No records found.') }}</div>
        <div v-else class="table-responsive">
          <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
          <table v-else class="table table-striped table-sm table-nowrap table-centered">
            <thead>
              <tr>
                <th class="border-0 text-center" v-for="th in table.heade" :key="th">{{ t(th) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td,index) in table.body" :key="index">
                <td><img style="height:40px;" src="@/assets/images/logo-icon.png" alt="" /></td>
                <td class="px-3 text-center"><h5 class="mb-0">{{ td.number }}</h5></td>
                <td class="px-3 text-center"><p class="mb-0">{{ td.date }}</p><h5 class="mb-0">{{ td.hour }}</h5></td>
                <td class="px-3 text-center">
                  <h5 class="mb-0">{{ td.value }}</h5>
                </td>
                <td class="px-3 text-center">
                  <h5 class="mb-0">{{ td.premiation }}</h5>
                  <p class="mb-0">{{ td.one_line }} | {{ td.double_line }} | {{ td.bingo }}</p>
                </td>
                <td class="text-right">
                  <button class="btn btn-danger rounded-pill mr-2 px-3 font-weight-bold" @click="getCard(index)"><i class="bx bx-star font-size-18 text-white align-top"></i> PARTICIPAR</button>
                  <!-- <a class="btn btn-info rounded-pill px-3 font-weight-bold" href=""><i class="bx bx-tv font-size-18 text-white align-top"></i> AO VIVO</a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal v-model="buy.modal" :title="'Sorteio nº ' + buy.number" centered @hide="closeCard()">
      <b-form @submit.prevent="buyCard" class="text-center">
        <div v-if="buy.alert.message" :class="'alert ' + buy.alert.type">{{ t(buy.alert.message) }}</div>
        <p class="font-weight-medium">Escolha uma quantidade de cartelas</p>
        <div class="mb-4">
          <a class="btn btn-danger text-white mr-1 mb-2" @click="buy.qty = 5">5</a>
          <a class="btn btn-danger text-white mr-1 mb-2" @click="buy.qty = 10">10</a>
          <a class="btn btn-danger text-white mr-1 mb-2" @click="buy.qty = 15">15</a>
          <a class="btn btn-danger text-white mr-1 mb-2" @click="buy.qty = 30">30</a>
          <a class="btn btn-danger text-white mr-1 mb-2" @click="buy.qty = 50">50</a>
        </div>
        <b-form-group id="qty" label="Ou digite aqui" label-for="qty">
          <div class="d-flex mb-4">
            <div class="align-self-center pt-1 pr-2">
              <a class="btn p-0" v-on:click="[buy.qty > 0 ? buy.qty-- : buy.qty = 0]"><i class="bx bx-minus-circle font-size-24"></i></a>
            </div>
            <b-form-input id="qty" v-model="buy.qty" type="text" class="text-center font-size-24" :class="{ 'is-invalid': $v.buy.qty.$error }" autocomplete="off"></b-form-input>
            <div class="align-self-center pt-1 pl-2">
              <a class="btn p-0" v-on:click="buy.qty++"><i class="bx bx-plus-circle font-size-24"></i></a>
            </div>
          </div>
          <div v-if="$v.buy.qty.$error" class="invalid-feedback">
            <span v-if="!$v.buy.qty.required">{{ t('Qty is required.') }}</span>
          </div>
        </b-form-group>
        <div class="row mb-4">
          <div class="col-6 py-2 text-center">
            <p class="mb-0">Doação</p>
            <h5 class="mb-0">R$ {{ ((buy.value*1).toFixed(2).replace('.', ',')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</h5>
          </div>
          <div class="col-6 py-2 text-center border border-danger border-top-0 border-right-0 border-bottom-0">
            <p class="mb-0">Total</p>
            <h5 class="mb-0">R$ {{ ((buy.value*buy.qty).toFixed(2).replace('.', ',')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</h5>
          </div>
        </div>
        <b-button :disabled="buy.loading == true || this.buy.qty <= 0" type="submit" class="btn-lg" variant="danger">
          {{ t('Confirmar') }}
          <b-spinner v-if="buy.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>